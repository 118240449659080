import React, { useState, useEffect } from 'react'
import {
  Box,
  Container,
  Text,
  ScaleFade,
  GridItem,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { BsStarFill, BsStarHalf } from 'react-icons/bs'
import { useStaticQuery, graphql } from 'gatsby'

const Testimonial = ({ timer = 100, children }) => {
  const [active, setActive] = useState(0)

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  useEffect(() => {
    // return () => clearTimeout(id)
    async function nextSlide() {
      await delay(timer)
      let next
      if (active >= children.length - 1) next = 0
      else next = active + 1
      setActive(next)
    }
    nextSlide()
  }, [active, children.length, timer])

  return (
    <Box justify="center" align="center">
      {children.map((n, index) => (
        <ScaleFade in={active === index} key={index + 'testimonial'}>
          <Box
            w="100%"
            sx={active === index ? { display: 'block' } : { display: 'none' }}
          >
            {n}
          </Box>
        </ScaleFade>
      ))}
    </Box>
  )
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

export const Banner = ({ limit = 40 }) => {
  const starColour = useColorModeValue(`brand.taupe.100`, `brand.dark_gray.500`)
  const starColourEmpty = useColorModeValue(
    `brand.orange.500`,
    `brand.dark_gold.500`
  )
  const color = useColorModeValue('light.color', 'light.color')

  function sanitizeString(str) {
    let stringy
    stringy = str.replaceAll('&#x27;', "'")
    stringy = stringy.replaceAll('&amp;', '&')
    stringy = stringy.replaceAll('<', '')
    if (stringy.length > 285) {
      stringy = stringy.substring(0, 285) + '...'
    }

    return stringy.trim()
  }

  var start = getRandomInt(40 - limit)

  const data = useStaticQuery(graphql`
    query yotPoReviews {
      reviews: allYotpoProductReview(
        limit: 40
        sort: { fields: productIdentifier }
        filter: { sentiment: { gte: 0.9 } }
      ) {
        nodes {
          name
          reviewerType
          content
          score
        }
      }
    }
  `)
  return (
    <GridItem colStart="1" colSpan={[1, 2, 2, 3]} mt={1}>
      <Container pr={2} pl={2} minH={['200px', '175px', '150px', '125px']}>
        <Testimonial timer={4000}>
          {data.reviews.nodes.slice(start, start + limit).map((n) => (
            <Box key={sanitizeString(n.content)}>
              <Box
                w="100%"
                bg="brand.palepink.100"
                _dark={{
                  bg: 'brand.dark_lightgray.500',
                }}
                pt={6}
                pb={6}
                sx={{
                  clipPath:
                    'polygon(0.09% 5.41%, 11% 2%, 17.43% 2.83%, 34.57% 3.83%, 45.51% 6.45%, 61% 4%, 88.32% 2.53%, 94.22% 2.58%, 97% 0%, 99.88% 0.58%, 100% 24.68%, 99.88% 62.7%, 99.67% 78.57%, 94.98% 85.35%, 87% 84.07%, 72.14% 92.69%, 62% 97%, 56% 100%, 43% 100%, 32.8% 99.71%, 25% 90.42%, 16.91% 87.38%, 8.23% 86.21%, 1.83% 81.03%, 0% 73.93%, 0% 52%, -0.29% 25.18%)',
                }}
              >
                <Box
                  pr={1}
                  pl={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Text fontSize="lg" as="b" color={color} minH={'3rem'}>
                    "{sanitizeString(n.content)}"
                  </Text>

                  <Text fontSize="sm" as="cite" color={color}>
                    {n.name}
                  </Text>
                  <Box display="flex" alignItems="baseline">
                    {Array(5)
                      .fill('')
                      .map((_, i) => (
                        <Icon
                          as={
                            i + 1 <= n.score
                              ? BsStarFill
                              : i + 0.8 < n.score
                              ? BsStarFill
                              : i >= n.score
                              ? BsStarFill
                              : BsStarHalf
                          }
                          key={i}
                          fill={i < n.score ? starColourEmpty : starColour}
                          boxSize={8}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Testimonial>
      </Container>
    </GridItem>
  )
}

export default Banner
