import React from 'react'
import { graphql, Link } from 'gatsby'
import {
  Container,
  Heading,
  Box,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Link as ChakraLink,
  Flex,
  useMediaQuery,
  Center,
} from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ProductListingFilter from '../components/product-listing-filter'
import { FilterProvider } from '../components/filter-context'
import ProductListingOnly from '../components/product-listing-only'
import USPIconBar from '../components/usp-icon-bar'
import Hero from '../components/hero'

import TestimonialBanner from '../components/testimonial-banner'
import NewsletterBanner from '../components/newsletter-banner'
import { StaticImage } from 'gatsby-plugin-image'
import { TagmanagerHead } from '../components/tagmanager'

const MeetChristianBlock = (props) => {
  return (
    <Container
      {...props}
      py={6}
      bg="brand.asparagus.300"
      _dark={{
        bg: 'brand.dark_gray.600',
      }}
      sx={{
        clipPath:
          'polygon(0% 0%, 23% 1%, 40% 1%, 49% 0%, 84% 1%, 89% 3%, 98% 1%, 100% 0%, 100% 51%, 100% 100%, 90% 100%, 83% 100%, 81% 96%, 69% 98%, 59% 99%, 53% 99%, 44% 99%, 39% 99%, 31% 99%, 23% 100%, 18% 100%, 12% 98%, 5% 99%, 0% 99%)',
      }}
    >
      <Flex
        gap={6}
        alignItems="center"
        justifyContent={'center'}
        flexWrap={['wrap-reverse', 'wrap-reverse', 'nowrap']}
      >
        <Box flex={1} w={['100%', '100%', 'auto']}>
          <Box
            p={0}
            flex={1}
            minWidth={['100%', '100%', '400px']}
            w="500px"
            maxW="100%"
            data-name="product-image-box"
            sx={{
              float: 'right',
              clipPath:
                'polygon(3.65% 1.86%, 23% 1%, 40% 1%, 52.1% 2.66%, 67.58% 4.98%, 81.16% 4.32%, 89.24% 4.72%, 99.27% 5.58%, 100% 51%, 99.45% 96.55%, 91.64% 96.81%, 85.01% 95.22%, 71.51% 97.06%, 55.5% 96.67%, 36.19% 94.49%, 21.72% 96.02%, 11.09% 96.94%, 3% 96.35%, 2.37% 48.8%)',
            }}
          >
            <StaticImage
              src="../images/mud-christian-72.jpg"
              alt="Founder of Mudfoods"
              layout="constrained"
              formats={['auto', 'webp']}
              placeholder="blurred"
            />
          </Box>
          <Heading
            as="h3"
            fontSize="xl"
            transition="color 0.25s ease-in-out"
            my={4}
          >
            Meet Christian, Chief Pie Maker
          </Heading>

          <Text mb={3}>
            Hello, I’m Christian, the founder and Chief Pie Maker of Mud Foods.
            I come from a long line of foodies and my passion for cooking
            started at a young age when I used to help my grandmother in the
            kitchen, experimenting with family recipes passed down from
            generations.
          </Text>
          <Text mb={3}>
            My expertise is in pastry, and I have a love for meat pies,
            vegetarian pies, and pretty much anything wrapped in pastry, but it
            must be butter, not margarine! At Mud Foods, my team and I work
            tirelessly to create innovative and delicious foods that our
            customers will love.
          </Text>
          <Link to="meet-the-team">
            <Heading as="h4" size="md">
              Meet the team
            </Heading>
          </Link>
        </Box>
      </Flex>
    </Container>
  )
}

const IndexPage = ({
  data: { featuredproducts, sides, sweet, allYotpoProductBottomline },
}) => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')
  return (
    <Layout>
      <Seo
        title={`Buy Pies Online | Award Winning Pies | Mud Foods`}
        description={`Award-winning pies to buy online. Artisan Steak Pies, Chicken Pies and much more. Buy hand-made pies online today for UK delivery.`}
      />

      <Hero />
      <USPIconBar />

      <Container my={4}>
        <FilterProvider>
          <Heading
            mb={4}
            mt={1}
            pt={2}
            as="h1"
            size="xl"
            transition="color 0.25s ease-in-out"
            my={4}
            textAlign={'center'}
          >
            Buy Award Winning Pies Online
          </Heading>
          <Center>
            <Text width={'920px'} textAlign={'center'}>
              At Mud, it's not just the quality of our ingredients and our
              unerring attention to detail that we believe creates our
              deliciously wholesome foods, it's also our small friendly team of
              Pie-fectionists, who all share a passion and obsession for making
              down to earth good food.
            </Text>
          </Center>
          <ProductListingFilter
            inclusionList={[
              'steak',
              'chicken',
              'speciality',
              'veggie/vegan',
              'squiche',
              'fruit',
              'side',
              'gift',
            ]}
            products={featuredproducts.products}
            textAlign={'center'}
          ></ProductListingFilter>

          <ProductListingOnly
            mt={12}
            bottomlines={allYotpoProductBottomline.nodes}
            maximum={4}
          />

          <NewsletterBanner emailId="k_email_x" />

          <ProductListingOnly
            mt={12}
            bottomlines={allYotpoProductBottomline.nodes}
            offset={5}
            maximum={4}
          />

          <MeetChristianBlock mt={10} mb={10} />

          <ProductListingOnly
            mt={12}
            bottomlines={allYotpoProductBottomline.nodes}
            offset={9}
            maximum={4}
          />
        </FilterProvider>

        {isLargerThan800 ? (
          <TestimonialBanner />
        ) : (
          <TestimonialBanner limit={1} />
        )}

        <Box mt={4}>
          <h3>Mud Foods Info</h3>
          <Accordion allowToggle>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Buy Pies Online
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                The delicious taste of Mud’s pies is available without leaving
                your home. Order pies online from Mud Foods and enjoy the best
                pies delivered safely and securely, directly to your home. Pies
                by post? Yes, our mail order pies are handmade with high quality
                ingredients and baked to perfection. Try them today!
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Award-winning Pies
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  At Mud Foods, our pies have received critical acclaim at the
                  renowned British Pie Awards and the Great Taste Awards. Enjoy
                  the supreme taste of our award-winning pies, which include a
                  variety of champion winning pies such as{' '}
                  <ChakraLink variant="inline" href="/products/steak-ale-pie">
                    Steak and Ale Pie
                  </ChakraLink>
                  ,{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/chicken-ham-leek-pie"
                  >
                    Chicken Ham and Leek Pie
                  </ChakraLink>
                  ,{' '}
                  <ChakraLink variant="inline" href="/products/game-pie">
                    Game Pie
                  </ChakraLink>
                  ,{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/mushroom-and-asparagus-pie-1"
                  >
                    Mushroom and Asparagus Pie
                  </ChakraLink>
                  ,{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/gluten-free-steak-and-kidney-pie"
                  >
                    Gluten Free Steak and Kidney Pie
                  </ChakraLink>{' '}
                  and fruit pies like our{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/apple-and-blackberry-pie-serves-2"
                  >
                    Apple and Blackberry Pie
                  </ChakraLink>
                  .
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Our Pastry
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  At Mud Foods, we take great pride in crafting the most
                  delicious pies, and it all starts with our handmade buttery
                  shortcrust pastry which is enriched with local free-range
                  eggs. We also make gluten-free pastry to the same high
                  standards, so coeliacs, or those just looking to cut down on
                  gluten, can also enjoy our scrumptious pies.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Pie Fillings
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Indulge in the freshest, locally sourced ingredients in every
                  Mud pie, ensuring the best taste and flavour in every bite.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you sell Meat Pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Mud Foods uses only the finest, hand cut, locally sourced meat
                  to make our savoury meat pies, including 28-day matured steak
                  from grass-fed cows, free-range British chicken, ham from
                  outdoor-reared pigs, and wild game from the South Downs.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you sell Vegan Pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Mud Foods caters to all dietary requirements, with our
                  Champion winning vegan pie using fresh, locally sourced
                  ingredients like{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/squash-spinach-and-vegan-feta-pie"
                  >
                    butternut squash, spinach, and a vegan feta cheese.
                  </ChakraLink>
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you sell Vegetarian Pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Vegetarians can enjoy Mud Foods' range of mouth-watering pies,
                  hand crafted with the freshest, locally sourced ingredients
                  like cheese, our homemade red onion marmalade, roasted
                  vegetables, feta cheese, and we have a great selection of
                  vegetarian side dishes to accompany your pies, such as{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/beer-battered-chips-serves-2"
                  >
                    beer-battered chips
                  </ChakraLink>
                  .
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you sell Gluten-Free Pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  We've updated our range to bring you the best of our
                  traditional pies. As a result, we've said a fond farewell to
                  our gluten-free options for the moment.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Do you sell Fruit Pies?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Indulge in the juicy richness of our fruit pies, crafted with
                  fresh ingredients like black cherries, apples, blackberries,
                  and apricots, encased in our all-butter shortcrust pastry –
                  just add your favourite ice cream, cream or custard for the
                  taste of pie-fection.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    What Is The Most Popular Selling Pie?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Mud Foods’ extensive range of pies is a winner among
                  customers, but the Steak Pies and Chicken Pies are the most
                  popular pies and the original{' '}
                  <ChakraLink
                    variant="inline"
                    href="/products/chip-shop-chicken-curry-pie"
                  >
                    Chip Shop Chicken Curry pie
                  </ChakraLink>{' '}
                  being one of the most popular selling pies and the winner of
                  the Channel 4 TV competition Aldi’s Next Big Thing.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Pie Delivery information
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Experience the convenience of our online pie delivery service,
                  available to most UK postcodes via our trusted and reliable
                  courier partners. Mud Foods carefully packs your freshly baked
                  pies in specially designed chilled packaging which arrives
                  safely and securely packaged for the ultimate pie experience.
                </Text>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h3>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Can we buy Mud Foods from Stockists?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel pb={4}>
                <Text>
                  Mud Foods is available at a range of authorised fine food
                  stores, making it easier than ever to get your hands on our
                  delicious pies and squiches. Use our stockist checker tool on
                  our website to{' '}
                  <Link variant="inline" to="/stockists">
                    find your nearest Mud Pie retailer.
                  </Link>
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  fragment SingleCollection on ShopifyCollection {
    title
    handle
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 200, aspectRatio: 1, formats: [AUTO, WEBP])
        }
      }
    }
  }
  {
    christian: file(relativePath: { eq: "mud-christian-72.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    featuredproducts: shopifyCollection(handle: { eq: "best-selling" }) {
      handle
      products {
        ...ProductCard
      }
    }
    allYotpoProductBottomline {
      nodes {
        score
        totalReviews
        productIdentifier
      }
    }
  }
`
