import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Text,
  useMediaQuery,
  Grid,
  Image,
  useColorModeValue,
} from '@chakra-ui/react'
import Newsletter from './newsletter-serverside'
import { StaticQuery, graphql } from 'gatsby'

const Banner = ({ timer = 800, emailId, ...props }) => {
  const color = useColorModeValue('light.color', 'light.color')
  const [isLargerThan640] = useMediaQuery('(min-width: 640px)')
  const [active, setActive] = useState(0)

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  useEffect(() => {
    async function nextSlide() {
      await delay(timer)
      let next
      if (active === 1) next = 0
      else next = 1
      setActive(next)
    }
    nextSlide()
  }, [active, timer])

  return (
    <StaticQuery
      query={graphql`
        query localImages {
          signup: file(relativePath: { eq: "newsletter/sign-up.png" }) {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          signupBlank: file(
            relativePath: { eq: "newsletter/sign-up-blank.png" }
          ) {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          pie: file(relativePath: { eq: "newsletter/pie-icon.png" }) {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          pieBlank: file(
            relativePath: { eq: "newsletter/pie-icon-blank.png" }
          ) {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container pr={4} pl={4} {...props}>
          <Box sx={{ filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.6))' }}>
            <Box
              w="100%"
              bg="brand.asparagus.300"
              _dark={{
                bg: 'brand.dark_brown.500',
              }}
              py={2}
              sx={{
                clipPath:
                  'polygon(0.09% 5.41%, 11% 2%, 17% 8%, 35% 9%, 45% 3%, 61% 4%, 88.32% 2.53%, 94.22% -2.59%, 97% 0%, 99.11% 0.58%, 100% 16.06%, 99.88% 62.7%, 99.49% 100.12%, 93.78% 100%, 87% 97%, 73% 97%, 62% 97%, 56% 100%, 43% 100%, 32.8% 99.71%, 25% 93%, 17% 96%, 9% 100%, 1.23% 100%, 0% 86%, 0% 52%, -0.29% 25.18%)',
              }}
            >
              <Box
                textAlign="center"
                justify="center"
                align="center"
                width="100%"
                color={color}
              >
                <Grid
                  gridTemplateColumns={['auto', 'auto', '2fr 2fr 2fr 1fr']}
                  alignItems="center"
                  justifyContent="center"
                  gap={[3, 3, 6]}
                >
                  <Box pl={(0, 0, 6)} height="100px" width="250px">
                    {active ? (
                      <Image
                        src={data.signup.childImageSharp.fluid.src}
                        alt=""
                      />
                    ) : (
                      <Image
                        src={data.signupBlank.childImageSharp.fluid.src}
                        alt=""
                      />
                    )}
                  </Box>
                  {isLargerThan640 ? (
                    <Text
                      pr={[2, 2]}
                      pl={[2, 2]}
                      w={['100%', '100%', 'auto']}
                      textAlign="center"
                      fontSize="lg"
                      flexShrink="1"
                      maxW="300px"
                    >
                      Get <strong>10% off</strong> your first order and a chance
                      to <strong>win free pies</strong> in our monthly draw!{' '}
                    </Text>
                  ) : (
                    <Text
                      pr={[2, 2]}
                      pl={[2, 2]}
                      w={['100%', '100%', 'auto']}
                      textAlign="center"
                      fontSize="lg"
                      flexShrink="1"
                      maxW="300px"
                    >
                      Get <strong>10% off</strong> your first order
                    </Text>
                  )}
                  <Box flex={1} pb={[4, 0]} maxW="400px">
                    <Newsletter
                      colorScheme="brand.purple"
                      emailId={emailId}
                      inline={true}
                      showLabel={false}
                    />
                  </Box>
                  {isLargerThan640 && (
                    <Box pr={(0, 0, 0)} boxSize="100px">
                      {active ? (
                        <Image
                          src={data.pie.childImageSharp.fluid.src}
                          alt=""
                          boxSize="100px"
                        />
                      ) : (
                        <Image
                          src={data.pieBlank.childImageSharp.fluid.src}
                          alt=""
                          boxSize="100px"
                        />
                      )}
                    </Box>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    />
  )
}

export default Banner
